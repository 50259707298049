enum AllRoutesEnum {
  Root = '/',
  SSO = '/sso',
  Error = '/error',
  NewScan = '/new-scan',
  RunScan = '/new-scan/:type',
  Home = '/home',
  Profile = '/profile',
  Settings = '/settings',
  Scans = '/scans',
  ScanDetails = '/scans/:scanId',
  ScanDetailsFindings = '/scans/:scanId/findings',
  ScanDetailsIssueKind = '/scans/:scanId/findings/:kindId',
  ScanDetailsSharing = '/scans/:scanId/findings/:kindId/:issueId',
  Issues = '/issues',
  IssueKindDetails = '/issues/:kindId',
  Targets = '/targets',
  TargetDetails = '/targets/:targetId',
  Projects = '/projects',
  ProjectDetails = '/projects/:projectId',
  ProjectDetailsCollaborators = '/projects/:projectId/collaborators',
  ProjectTargetDetails = '/projects/:projectId/targets/:targetId',
  ProjectAuthenticationDetails = '/projects/:projectId/authentications/:authenticationId',

  /* --- TODO: The credentials are outdated. Delete after "enableApplications" flag is always on --- */
  Credentials = '/credentials',
  CredentialDetails = '/credentials/:credentialId',
  /* ------- */

  Authentications = '/authentications',
  AuthenticationDetails = '/authentications/:authenticationId',
  NucleiTemplates = '/nuclei-templates',
  NucleiTemplateDetails = '/nuclei-templates/:nucleiTemplateId',
  GetStarted = '/get-started',
  SelectBillingPlan = '/choose-plan',
  ContactSales = '/contact-sales',
  // Documentation authentication page
  Documentation = '/docs',
  // Error pages
  NotFound = '/page404',
  NoAccess = '/page403',
  ScanDetailsFindingsShowcase = '/vulnerable-showcase/:id',
}

export default AllRoutesEnum;
