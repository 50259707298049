import { Project } from '@api-client';
import { UIMenu } from '@shared/components';
import { globalTypes, globalQueries, globalUtils, allRoutesEnum, globalConstants } from '@shared/duck';
import { useApp } from '@shared/hooks';
import React from 'react';
import { ErrorContext, MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { ScanStatusEnum } from 'models/Enums';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
interface InfoMenuListProps {
  scanId: string;
  project: Project | undefined;
  setOpenShareProjectModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const InfoMenuList: React.FC<InfoMenuListProps> = ({
  scanId,
  project,
  setOpenShareProjectModal,
}) => {
  const { scansApi, users } = useApp();
  const [itemList, setItemList] = React.useState<globalTypes.UIMenuItemsList[]>([]);
  const { showModal } = useGlobalModalContext();
  const navigate = useNavigate();
  const app = useApp();
  const isScanLimitReached = app.checkIfScanLimitReached();

  const { setError } = React.useContext(ErrorContext);
  const { scan } = globalQueries.useGetScan({ id: scanId }, {
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  const onCopyScanUrlClick = () => {
    let url: string;

    if (scan?.target_id) {
      url = location.origin + `${allRoutesEnum.ScanDetails.replace(':scanId', scanId)}`;
    } else {
      url = location.href;
    }

    globalUtils.copyTextToClipboard(url, 'Copied to clipboard!');
  };

  const onCopyScanIdClick = () => {
    globalUtils.copyTextToClipboard(scan?.id || '', 'Copied to clipboard!');
  };

  const EXPORT_CSV: globalTypes.UIMenuItemsList = {
    title: 'Export Report',
    itemAction: async () => {
      await scansApi.scansReportCsvRetrieve({ id: scanId });
      enqueueSnackbar('We are processing your scan report. You will receive it via email soon.', { variant: 'info' });
    },
  };

  const COPY_URL: globalTypes.UIMenuItemsList = {
    title: 'Copy Scan URL',
    itemAction: onCopyScanUrlClick,
  };

  const COPY_SCAN_ID: globalTypes.UIMenuItemsList = {
    title: 'Copy Scan ID',
    itemAction: onCopyScanIdClick,
  };

  const { deleteScanHandler } = globalUtils.useDeleteScanHandler();
  const onDeleteScan = async () => {
    const isSuccess = await deleteScanHandler(scan?.id || '', scan?.target_name || '');
    if (isSuccess) {
      navigate(allRoutesEnum.Scans);
    }
  };

  const DELETE_SCAN: globalTypes.UIMenuItemsList = {
    title: 'Delete Scan',
    itemAction: onDeleteScan,
    isRedText: true,
  };

  React.useEffect(() => {
    if (project?.id) {
      const tooltipText = scan?.project.is_deleted
        ? globalConstants.RESCAN_IS_NOT_AVAILABLE_PROJECT_DELETED
        : scan?.target.is_deleted
          ? globalConstants.RESCAN_IS_NOT_AVAILABLE_TARGET_DELETED
          : scan?.credentials?.is_deleted
            ? globalConstants.RESCAN_IS_NOT_AVAILABLE_AUTH
            : undefined;

      const isProjectOwner = users.me?.dto.id === project?.own_user.id;

      const newItemsList: globalTypes.UIMenuItemsList[] = [];

      !isScanLimitReached && newItemsList.push({
        title: 'Re-run the scan',
        itemAction: () => showModal({ modalType: MODAL_TYPE.RescanPopupModal, modalProps: { scanId } }),
        disabled: Boolean(tooltipText),
        tooltipText: tooltipText,
      });

      if (scan?.status !== ScanStatusEnum.IN_PROGRESS) {
        newItemsList.push(EXPORT_CSV);
      }

      if (!scan?.project.is_deleted) {
        newItemsList.push({
          title: 'Share Project containing this scan',
          itemAction: () => setOpenShareProjectModal(true),
          disabled: !isProjectOwner || project?.is_free,
          tooltipText: globalUtils.getShareProjectTooltipText(isProjectOwner, project?.is_free),
        });
      }

      newItemsList.push(COPY_URL);
      newItemsList.push(COPY_SCAN_ID);

      if (!scan?.is_deleted) {
        newItemsList.push(DELETE_SCAN);
      }
      setItemList(newItemsList);
    }
  }, [scan, project, isScanLimitReached]);

  /*  React.useEffect(() => {
   if (scan?.status !== ScanStatusEnum.IN_PROGRESS) {
   setItemList((old) => [...old, EXPORT_CSV]);
   }
   }, [scan?.status]);
   */

  return (
    itemList.length > 0 && (
      <UIMenu
        itemList={itemList}
      />
    )
  );
};

export default InfoMenuList;