import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { allRoutesEnum } from '@shared/duck';
import { BorderedTabs } from './scan-details.styled';
import { ScanDetailsInfo } from './components';
import { Paper, Tab } from '@mui/material';

type ScanDetailsTabName = 'findings' | 'checks' | 'paths' | 'login-status' | 'logs' | 'scan-parameters' | 'reports';

interface ScanDetailsProps {
  tabId: ScanDetailsTabName;
  children: React.ReactNode;
}

const ScanDetails: React.FC<ScanDetailsProps> = ({ tabId, children }: ScanDetailsProps) => {
  const { scanId = '' } = useParams();
  const navigate = useNavigate();

  const onTabChange = (tabId: ScanDetailsTabName) => {
    navigate(`${allRoutesEnum.ScanDetails.replace(':scanId', scanId)}/${tabId}`, { replace: true });
  };

  return (
    <>
      {scanId && (
        <ScanDetailsInfo scanId={scanId} />
      )}
      <Paper variant='outlined' sx={{ display: 'flex', flexDirection: 'column', borderRadius: '0.375rem', overflow: 'hidden' }}>
        <BorderedTabs value={tabId} onChange={(_, tab) => onTabChange(tab as ScanDetailsTabName)} variant='scrollable' allowScrollButtonsMobile>
          <Tab value='findings' label='Findings' />
          <Tab value='checks' label='Security Checks' />
          <Tab value='paths' label='Paths Scanned' />
          <Tab value='login-status' label='Login Status' />
          <Tab value='logs' label='Logs' />
          <Tab value='scan-parameters' label='Scan Parameters' />
          <Tab value='reports' label='Reports' />
        </BorderedTabs>
        {children}
      </Paper>
    </>
  );
};

export default ScanDetails;
