import { GitHubTargetConfiguration, OpenApiTarget } from '@api-client';
import { globalConstants, globalUtils } from '@shared/duck';
import * as Yup from 'yup';
import { targetExclusionsUtils } from '@shared/components';
import { ApiTargetsContentValues } from './types';
import { enqueueSnackbar } from 'notistack';

export const importTargetsValidationSchema = Yup.object().shape({
  newTarget: Yup.object().shape({
    configuration: targetExclusionsUtils.TargetExclusionsValuesSchema,
  }).when('isFileUrl', {
    is: true,
    then: schema =>
      schema.shape({
        name: Yup.string().when('exists', {
          is: true,
          then: schema => schema.test('exists', 'This name is already taken in this Project', () => false),
          otherwise: schema => schema.required('Please enter target name').max(100, 'Name must be at most 100 characters')
            .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
              return globalUtils.validateName(value || '');
            }),
        }),
        isNameTested: Yup.boolean().oneOf([true], 'Name must be tested').required('Please test the name'),
        isAccessTested: Yup.boolean().oneOf([true], 'Url must be tested').required('Please test the url'),
        location: Yup.string().required('Base target URL is required')
          .test('isValid', 'Invalid format', (value?: string) => {
            const newUrls = value?.split('\n').filter(url => url.length);
            return globalUtils.validateUrls(newUrls || []);
          }),
        fileLocation: Yup.string().required('Please enter the OpenAPI file location or attach an existing OpenAPI file')
          .test('isValid', 'Invalid format', (value?: string) => {
            const newUrls = value?.trim();
            return globalUtils.validateUrls([newUrls || '']);
          })
          .when('isFileUrlValid', {
            is: false,
            then: schema => schema.test('fileUrlInvalid', globalConstants.INVALID_YAML_DEFINITION, () => false)
          })
          .when('isFileUrlValid2', {
            is: false,
            then: schema => schema.test('fileUrlInvalid2', globalConstants.INACCESSIBLE_URL, () => false)
          }),
        isFileUrlValid: Yup.boolean().oneOf([true], 'Must be valid Yaml data').required('Must be valid Yaml data'),
        isFileUrlValid2: Yup.boolean().oneOf([true], 'Must be valid Yaml data').required('Must be valid Yaml data'),
      }),
    otherwise: schema => schema.shape({
      name: Yup.string().when('exists', {
        is: true,
        then: schema => schema.test('exists', 'This name is already taken in this Project', () => false),
        otherwise: schema => schema.required('Please enter target name').max(100, 'Name must be at most 100 characters')
          .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
            return globalUtils.validateName(value || '');
          }),
      }),
      isAccessTested: Yup.boolean().oneOf([true], 'Url must be tested').required('Please test the url'),
      isNameTested: Yup.boolean().oneOf([true], 'Name must be tested').required('Please test the name'),
      location: Yup.string().required('Base target URL is required')
        .test('isValid', 'Invalid format', (value?: string) => {
          const newUrls = value?.split('\n').filter(url => url.length);
          return globalUtils.validateUrls(newUrls || []);
        }),
      file: Yup.mixed().nullable().required('Please attach an existing Swagger file'),
      isFileUrlValid: Yup.boolean().notRequired(),
      isFileUrlValid2: Yup.boolean().notRequired(),
    }),
  }),
});

const getErrorMessage = (error: any): string | undefined => {
  const response = error.response?.data['errors'][0];
  if (response.code === 'invalid') {
    return response.detail.toString();
  }
};

interface GetUtilsProps {
  openApiTarget?: OpenApiTarget;
  project?: string;
  onAfterUpdate?: (values: ApiTargetsContentValues) => void;
  onAfterCreate?: (values: ApiTargetsContentValues) => void;
  isFileEdited: boolean;
  selectedFile?: File;
}

interface GetUtilsResultProps {
  onUpdateApiTarget: (values: ApiTargetsContentValues) => void;
  onCreateApiTarget: (values: ApiTargetsContentValues) => void;
  getInitialValues: () => ApiTargetsContentValues;
}

export const useGetApiUtils = ({
  openApiTarget,
  project,
  onAfterUpdate,
  onAfterCreate,
  isFileEdited,
  selectedFile,
}: GetUtilsProps): GetUtilsResultProps => {
  const { onCreate } = globalUtils.onCreateNewTarget();
  const { onUpdate } = globalUtils.onUpdateNewTarget();

  const swaggerFileUrl = openApiTarget?.swaggerfile_url;

  const onCreateApiTarget = async (values: ApiTargetsContentValues) => {
    try {
      const configuration: GitHubTargetConfiguration = {
        excluded_url_patterns: values.configuration.excluded_url_patterns?.split('\n'),
        excluded_x_paths: values.configuration.excluded_x_paths?.split('\n'),
      };

      const response = await onCreate({
        ...values.newTarget,
        project: project || '',
        file: values.isFileUrl ? undefined : values.newTarget.file,
        fileLocation: values.isFileUrl ? values.newTarget.fileLocation : undefined,
      }, values.isFileUrl, configuration);

      onAfterCreate?.({ ...values, newTarget: { ...values.newTarget, targetId: response.id } });
      enqueueSnackbar('OpenAPI Target has been created successfully', { variant: 'success' });
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to create OpenAPI Target', { variant: 'error' });
    }
  };

  const onUpdateApiTarget = async (values: ApiTargetsContentValues) => {
    try {
      const isSwaggerUrlChanged = swaggerFileUrl?.trim() !== values.newTarget.fileLocation?.trim();
      const isExclusionsChanged = JSON.stringify(openApiTarget?.configuration) !== JSON.stringify(values.configuration);

      const configuration: GitHubTargetConfiguration = {
        excluded_url_patterns: values.configuration.excluded_url_patterns?.split('\n'),
        excluded_x_paths: values.configuration.excluded_x_paths?.split('\n'),
      };

      await onUpdate(
        { ...values.newTarget, project: project || '' },
        openApiTarget?.id || '',
        configuration,
        values.isFileUrl,
        isFileEdited,
        isSwaggerUrlChanged,
        isExclusionsChanged
      );

      onAfterUpdate?.({ ...values, newTarget: { ...values.newTarget, targetId: openApiTarget?.id || '' } });
      enqueueSnackbar('OpenAPI Target has been updated successfully', { variant: 'success' });
    }
    catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to update OpenAPI Target', { variant: 'error' });
    }
  };

  const getInitialValues = () => {
    return {
      newTarget: {
        location: openApiTarget?.location || '',
        name: openApiTarget?.name || '',
        fileLocation: swaggerFileUrl || '',
        file: openApiTarget ? swaggerFileUrl ? undefined : selectedFile : undefined,
        project: openApiTarget?.project || project,
        isNameTested: !!openApiTarget,
        isAccessible: undefined,
        isAccessTested: false,
        exists: false,
        targetId: '',
      },
      isFileUrl: openApiTarget ? !!swaggerFileUrl : true,
      configuration: {
        excluded_url_patterns: openApiTarget?.configuration?.excluded_url_patterns?.join('\n'),
        excluded_x_paths: openApiTarget?.configuration?.excluded_x_paths?.join('\n'),
      },
    };
  };

  return {
    onCreateApiTarget,
    onUpdateApiTarget,
    getInitialValues,
  };
};