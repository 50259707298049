/* eslint-disable */
enum AllSubRoutesEnum {
  Root = '/', //
  NewExampleGitHubTarget = '/example-targets',
  NewScanUrl = '/web',
  NewScanOpenApi = '/openapi',
  NewScanGoogleFiring = '/google-firing',
  ScanDetails = '/:scanId',
  ScanDetailsFindings = '/findings',
  ScanDetailsIssueKind = '/findings/:kindId',
  ScanDetailsSharing = '/findings/:kindId/:issueId',
  ScanDetailsPaths = '/paths',
  ScanDetailsChecks = '/checks',
  ScanDetailsLogs = '/logs',
  ScanParameters = '/scan-parameters',
  ScanDetailsLoginStatus = '/login-status',
  ScanDetailsReports = '/reports',
  TargetDetails = '/:targetId',
  // ===
  ProjectDetails = '/:projectId',
  ProjectTargets = '/targets',
  ProjectAuthentications = '/authentications',
  ProjectTargetDetails = '/targets/:targetId',
  ProjectAuthenticationsDetails = '/authentications/:authenticationId',
  ProjectCollaborators = '/collaborators',
  // ===
  AuthenticationsDetails = '/:authenticationId',
  NucleiTemplateDetails = '/:nucleiTemplateId',
}

export default AllSubRoutesEnum;
