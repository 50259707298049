import { CopyIconC, WorldIconC } from '@assets';
import { Stack, Tooltip, Typography, Button } from '@mui/material';
import {
  UIUserAvatar,
  UIIconFrame,
  MuiContentLoader,
} from '@shared/components';
import { FULL_MONTH_DATE_TIME } from '@shared/duck/constants';
import { isNumber } from 'lodash';
import React from 'react';
import dayjs from 'dayjs';
import { ScanStatusEnum } from 'models/Enums';
import { DisruptedIcon } from 'views/components/v1/Icons';
import { InfoMenuList } from './components';
import { useTechnologies } from '@shared/duck/utils/technologies';
import { Scan } from '@api-client';
import { scanInfoDetailsUtils } from './duck';
import { allRoutesEnum, globalEnums, globalQueries, globalUtils } from '@shared/duck';
import { Link, useNavigate } from 'react-router-dom';
import useTimer from 'views/utils/useTimer';
import { formatTimeSpan } from 'views/utils/dates';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { muiPalette } from '@shared/general-mui-theme';

interface ScanDetailsInfoProps {
  scanId: string;
}

const ScanDetailsInfo: React.FC<ScanDetailsInfoProps> = ({ scanId }) => {
  const fetchedScan = React.useRef<Scan | undefined>();

  const { scan, isScanLoading } = scanInfoDetailsUtils.getScans(scanId, fetchedScan);
  const target = scan?.target;

  const { project, isProjectLoading } = globalQueries.useGetProject({ id: target?.project || '' });
  const isScanDeleted = scan?.is_deleted
    || scan?.target.is_deleted
    || scan?.project.is_deleted;

  const [title, setTitle] = React.useState<string>('Loading...');
  const endedAt = scan?.ended_at ? dayjs(scan?.ended_at).format(FULL_MONTH_DATE_TIME) : '';

  React.useLayoutEffect(() => {
    const isLoading = isScanLoading;

    setTitle(isLoading ? 'Loading...' : (
      target?.name || scan?.target_name || 'N/A'
    ));
  }, [isScanLoading]);

  const technologies = useTechnologies();

  const tech = technologies.get(scan?.target_type);
  const Icon = tech?.icon || WorldIconC;

  const isInProgress = scan?.status === ScanStatusEnum.IN_PROGRESS;
  const { time: currentTime } = useTimer({
    fromDate: scan?.started_at || scan?.created_at || '',
    formatter: formatTimeSpan,
    isActive: isInProgress,
  });

  const normalStatusLabel = 'Scan date: ' + `${endedAt}`;

  const inProgressStatusLabel =
    `In Progress | ${scan?.percent_complete}% | ${currentTime}`;

  const statusLabel = isInProgress ? inProgressStatusLabel : normalStatusLabel;

  const isDisrupted =
    scan &&
    isNumber(scan?.status) &&
    [ScanStatusEnum.ABORTED, ScanStatusEnum.FAILED, ScanStatusEnum.TIMED_OUT].includes(scan.status);

  const getScanUrl = () => {
    return location.href;
  };

  const onCopyScanUrlClick = () => {
    const url = getScanUrl();

    globalUtils.copyTextToClipboard(url, 'Copied to clipboard!');
  };

  const navigate = useNavigate();

  const onShowPastScansClick = React.useCallback(() => {
    navigate(`${allRoutesEnum.Scans}?project=${project?.name}/${project?.id}&target=${target?.name}/${target?.id}`);
  }, [target, project]);

  const { showModal } = useGlobalModalContext();
  const openShareProjectModal = () => project && showModal({
    modalType: MODAL_TYPE.ShareProjectModal,
    modalProps: {
      collaborators: project.shared_with_users_preview,
      projectId: project.id,
      scanId,
    },
  });

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' pb='1rem'>
      <Stack flexWrap='wrap' alignItems='flex-start'>
        <Stack gap={1} flexWrap='wrap' flexDirection='row'>
          <UIIconFrame
            Icon={Icon}
            primaryColor={tech?.primaryColor}
            secondaryColor={tech?.secondaryColor}
            loading={isInProgress ? 'true' : undefined}
          />
          <Stack flexDirection='row' alignItems='center'>
            <Typography
              variant='h5'
              lineHeight='2.25rem'
              alignItems='center'
              display='inline'
            >
              {title}
              {isScanDeleted && (
                <Typography
                  fontWeight='400'
                  fontSize='1.25rem'
                  color={muiPalette.red?.[600]}
                  display='inline'
                >
                  &nbsp;(Deleted)
                </Typography>
              )}
            </Typography>

            {isDisrupted ? (
              <Tooltip
                arrow
                title='This scan was disrupted'
                placement='right'
              >
                <Link to={'..' + allSubroutesEnum.ScanDetailsLogs} style={{ lineHeight: 0 }}>
                  <DisruptedIcon />
                </Link>
              </Tooltip>
            ) : null}
          </Stack>
        </Stack>

        <MuiContentLoader
          isLoading={isScanLoading}
          variant='dots'
          size={3}
          padding='initial'
        >
          <Stack flexDirection='row' alignItems='center'>
            <Typography variant='body2'>{statusLabel}</Typography>
            <Typography variant='body2' color={muiPalette.grey?.[200]}>{'|'}</Typography>

            {!isInProgress && scan?.completed_in && (
              <>
                <Typography variant='body2'>
                  {`Completed in: ${formatTimeSpan(Math.round(scan?.completed_in))}`}
                </Typography>
                <Typography variant='body2' color={muiPalette.grey?.[200]}>{'|'}</Typography>
              </>
            )}

            <Stack flexDirection='row' alignItems='center'>
              <Typography variant='body2'>{'Initiated by:'}</Typography>
              <UIUserAvatar
                fullName={`${scan?.created_by?.first_name} ${scan?.created_by?.last_name}`}
                src={scan?.created_by?.avatar_url}
                titleColor={muiPalette.grey?.[500]}
                avatarSize={1.125}
                titleFontSize='0.75rem'
                titleLineHeight='1rem'
              />
            </Stack>
            <Typography variant='body2' color={muiPalette.grey?.[200]}>{'|'}</Typography>

            <Tooltip
              arrow
              title={getScanUrl().toString()}
              placement='right'
            >
              <Stack
                flexDirection='row'
                alignItems='center'
                onClick={onCopyScanUrlClick}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Typography variant='body2' color={muiPalette.blue?.[600]}>
                  {'Copy Scan URL '}
                </Typography>
                <CopyIconC
                  variant={globalEnums.CopyIconVariantsEnum.BLUE}
                  style={{
                    height: '1rem',
                    width: '1rem',
                  }}
                />
              </Stack>
            </Tooltip>
          </Stack>
        </MuiContentLoader>
      </Stack>
      <Stack direction='row'>
        <Button
          disabled={!target?.name || isProjectLoading}
          onClick={() => onShowPastScansClick()}
        >
          Show Past Scans
        </Button>
        <InfoMenuList
          scanId={scanId}
          project={project}
          setOpenShareProjectModal={openShareProjectModal}
        />
      </Stack>
    </Stack>
  );
};

export default ScanDetailsInfo;
