import { Project } from '@api-client';
import { globalQueries } from '@shared/duck';

interface GetInitialValuesResultsProps {
  defaultProject?: Project;
  isDefaultProjectLoading: boolean;
}

export const useGetInitialValues = (): GetInitialValuesResultsProps => {
  const { defaultProject = null, isDefaultProjectLoading } = globalQueries.useGetDefaultProject();

  return {
    defaultProject: defaultProject || undefined,
    isDefaultProjectLoading,
  };
};