import React from 'react';
import { Box, Button, FormControlLabel, Grid, Radio, Stack, Typography } from '@mui/material';
import { globalConstants, globalEnums, globalQueries, globalUtils } from 'shared/duck';
import { useFormikContext } from 'formik';
import { FormikControlMui, UIFileInput } from 'shared/components';
import { formDataTypes } from '../../duck';
import { OpenApiTarget } from 'api-client';
import { CliRemovedFileWrapper, WebRadioGroup } from './file-select.styled';
import { SpecUrlInput } from './components';
import { muiPalette } from 'shared/general-mui-theme';
import { useLaunchDarklyFlags } from 'shared/hooks';

interface FileSelectProps {
  cliFlow: boolean;
}

const FileSelect: React.FC<FileSelectProps> = ({
  cliFlow,
}) => {
  const supportedTypes = [
    globalEnums.EFileExtension.YAML,
    globalEnums.EFileExtension.JSON,
  ];

  const { scanWizardNewFileSelect, strictSwaggerSpecValidation } = useLaunchDarklyFlags();

  const { values, setFieldValue, setFieldTouched, validateForm, touched } = useFormikContext<formDataTypes.FormDataFormValues>();
  const openApiTarget = values.target as OpenApiTarget;
  const [previousTargetId, setPreviousTargetId] = React.useState(values.target?.id);

  const { url } = globalQueries.useGetOpenApiSpecUrl({
    id: values.target?.id || '',
  }, {
    enabled: !!values.target,
  });

  React.useEffect(() => {
    // Don't recalculate all fields after Back button click
    if (previousTargetId !== values.target?.id) {
      if (openApiTarget?.swaggerfile_url) {
        if (scanWizardNewFileSelect) {
          setFieldValue('fileUrl', openApiTarget?.swaggerfile_url);
          setFieldValue('isFileUrl', true);
          setFieldValue('file', undefined);
        }
        else {
          const swaggerFileUrlName = openApiTarget?.swaggerfile_url?.substring(
            openApiTarget?.swaggerfile_url.lastIndexOf('/') + 1);
          const swaggerFileName = openApiTarget?.swaggerfile_name || swaggerFileUrlName || 'openapi-swagger.yaml';
          const file = new File([''], swaggerFileName, { type: 'application/yaml' });
          setFieldValue('file', file);
          setFieldValue('isFileUrl', false);
          setFieldValue('fileUrl', '');
        }
      }
      else if (openApiTarget?.swaggerfile_name) {
        const swaggerFileName = openApiTarget?.swaggerfile_name || 'openapi-swagger.yaml';
        const file = new File([''], swaggerFileName, { type: 'application/yaml' });
        setFieldValue('file', file);
        setFieldValue('isFileUrl', false);
        setFieldValue('fileUrl', '');
      }
      else {
        setFieldValue('fileUrl', '');
        setFieldValue('file', undefined);
      }

      setFieldValue('isFileUrlRemoved', false);
      setFieldValue('isFileRemoved', false);
      setPreviousTargetId(openApiTarget?.id);
    }
  }, [openApiTarget]);

  React.useEffect(() => {
    setFieldValue('fileRequired', !cliFlow);
    if (cliFlow) {
      setFieldValue('isFileUrlValid', true);
      setFieldValue('isFileUrlValid2', true);
    }
  }, [cliFlow]);

  const onSpecUrlChange = (e: any) => {
    if (e?.type === 'blur' && cliFlow && !e.target.value) {
      setFieldValue('isFileUrlRemoved', true);
      setFieldValue('isFileUrlValid', true);
      setFieldValue('isFileUrlValid2', true);
      validateForm({ ...values, isFileUrlRemoved: true, isFileUrlValid: true, isFileUrlValid2: true });
    }
    else {
      const newFileUrlRemovedValue = cliFlow ? false : !e.target.value;
      setFieldValue('isFileUrlRemoved', newFileUrlRemovedValue);
      setFieldValue('fileUrl', e.target.value);
      // All setters work asynchronously, but we need to run validation with correct data
      validateForm({ ...values, isFileUrlRemoved: newFileUrlRemovedValue, fileUrl: e.target.value });
    }
  };

  const onSpecFileChange = (file: File | null) => {
    const newFileValue = file || values.file;
    const newFileChangedValue = file ? true : values.isFileChanged;
    if (file) {
      setFieldValue('file', newFileValue);
      setFieldValue('isFileRemoved', false);
      setFieldValue('isFileChanged', newFileChangedValue);
    }
    else {
      setFieldValue('isFileRemoved', true);
    }
    setFieldTouched('file', true, false);
    setFieldValue('isFileUrlRemoved', false);
    setFieldValue('fileUrl', '');
    // All setters work asynchronously, but we need to run validation with correct data
    validateForm({ ...values, file: newFileValue, isFileRemoved: !file, isFileChanged: newFileChangedValue, isFileUrlRemoved: false, fileUrl: '' });
  };

  React.useEffect(() => {
    validateForm({ ...values });
  }, [values.isFileUrlValid2]);

  const onRestoreClick = () => {
    setFieldValue(showRestoreUrlButton ? 'isFileUrlRemoved' : 'isFileRemoved', false);
    values.isFileUrl && setFieldValue('fileUrl', values.fileUrl || openApiTarget?.swaggerfile_url, false);
  };

  const fileValidation = async (file: File): Promise<{ success: boolean; error?: string; }> => {
    const res = await globalUtils.validateOpenApiSpec({ api: file, isFile: true, strictCheck: strictSwaggerSpecValidation });
    return {
      success: !!res.isValid,
      error: res.isValid ? '' : globalConstants.INVALID_YAML_DEFINITION,
    };
  };

  const showFileGroup = !values.isFileUrl && !!values.file && !values.isFileChanged && (!!openApiTarget?.swaggerfile_name || !!openApiTarget?.swaggerfile_url);
  const showFileUrlGroup = values.isFileUrl && !!openApiTarget?.swaggerfile_url;
  const showFileComponent = cliFlow ? showFileGroup || showFileUrlGroup : true;
  const showUrlRadioText = cliFlow ? !!openApiTarget?.swaggerfile_url : true;
  const showRestoreUrlButton = cliFlow ? values.isFileUrlRemoved : false;
  const showRestoreFileButton = cliFlow ? values.isFileRemoved : false;
  const showRestoreButton = showRestoreUrlButton || showRestoreFileButton;

  const fileRequiredError = touched.file && (!values.file || values.isFileRemoved);
  const fileUrlRequiredError = touched.fileUrl && (!values.fileUrl || values.isFileUrlRemoved);
  const requiredError = values.isFileUrl ? fileUrlRequiredError : fileRequiredError;

  return (
    showFileComponent ? (
      <Grid item xs={12}>
        <Stack gap={0.5}>
          {!showRestoreButton && (
            <>
              {!cliFlow && scanWizardNewFileSelect && (
                <>
                  <Stack flexDirection='row'>
                    <Typography variant='subtitle2'>
                      {'API Specs'}
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      color={`${requiredError ? 'error' : muiPalette.grey?.['500']}`}
                      sx={{ opacity: `${requiredError ? '1' : '0.5'}` }}
                    >
                      {'(required)'}
                    </Typography>
                  </Stack>

                  <WebRadioGroup
                    row
                    value={values.isFileUrl ? 'url' : 'file'}
                    onChange={e => {
                      setFieldValue('isFileUrl', e.target.value === 'url');
                    }}
                  >
                    <FormControlLabel value='url' control={<Radio size='small' />} label='OpenAPI URL' />
                    <FormControlLabel value='file' control={<Radio size='small' />} label='Swagger File' />
                  </WebRadioGroup>
                </>
              )}

              {showUrlRadioText && values.isFileUrl ? (
                <FormikControlMui
                  name='fileUrl'
                  // label={cliFlow ? 'OpenAPI URL' : ''}
                  label={scanWizardNewFileSelect ? cliFlow ? 'OpenAPI URL' : '' : 'OpenAPI URL'}
                  requiredError={fileUrlRequiredError}
                  // remove line after scanWizardNewFileSelect is always enabled
                  required={!scanWizardNewFileSelect && !cliFlow}
                >
                  <SpecUrlInput
                    currentValue={values.isFileUrlRemoved ? '' : values.fileUrl}
                    onAfterChange={onSpecUrlChange}
                    setFileUrlValid={(res?: boolean) => setFieldValue('isFileUrlValid', res)}
                    setFileUrlValid2={(res?: boolean) => setFieldValue('isFileUrlValid2', res)}
                    initialValue={openApiTarget?.swaggerfile_url}
                  />
                </FormikControlMui>
              ) : (
                <FormikControlMui
                  name='file'
                  // label={cliFlow ? 'Swagger File' : ''}
                  label={scanWizardNewFileSelect ? cliFlow ? 'Swagger File' : '' : 'Swagger File'}
                  requiredError={fileRequiredError}
                  // remove line after scanWizardNewFileSelect is always enabled
                  required={!scanWizardNewFileSelect && !cliFlow}
                >
                  <UIFileInput
                    initFile={values.isFileRemoved ? undefined : values.file}
                    onAfterChange={onSpecFileChange}
                    deleteIcon={cliFlow ? false : true}
                    accept={supportedTypes}
                    helpText='.YML, .YAML, or .JSON'
                    downloadUrl={url?.url}
                    fileValidation={fileValidation}
                  />
                </FormikControlMui>
              )}
            </>
          )}

          {cliFlow && !showRestoreButton && (
            <Box>
              <Button onClick={() => {
                setFieldValue('isFileUrlValid', true);
                setFieldValue('isFileUrlValid2', true);
                if (values.isFileUrl) {
                  setFieldValue('isFileUrlRemoved', true);
                  validateForm({ ...values, isFileUrlRemoved: true, isFileUrlValid: true, isFileUrlValid2: true, });
                }
                else {
                  setFieldTouched('file', true);
                  setFieldValue('isFileRemoved', true);
                  validateForm({ ...values, isFileRemoved: true, isFileUrlValid: true, isFileUrlValid2: true, });
                }

              }}>
                Remove and add new file via CLI
              </Button>
            </Box>
          )}
          {showRestoreButton && (
            <CliRemovedFileWrapper>
              <Typography
                variant='subtitle2'
                component='i'
                onClick={onRestoreClick}
              >
                {`Restore Removed ${showRestoreUrlButton ? 'OpenAPI URL' : 'Swagger File'}`}
              </Typography>
            </CliRemovedFileWrapper>
          )}
        </Stack >
      </Grid>
    ) : <></>
  );
};

export default FileSelect;