import { ErrorContext } from '@shared/duck/contexts';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import ScanDetails from './scan-details';
import React from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import {
  ScanDetailsChecks,
  ScanDetailsFindings,
  ScanParameters,
  ScanDetailsLoginStatus,
  ScanDetailsLogs,
  ScanDetailsPaths,
  IssueDetails,
  // IssueSinglePage,
  ScanDetailsReports
} from './components';
import { globalQueries } from '@shared/duck';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { NotFoundErrorWrapper } from '@pages';

const SCAN_DETAILS = 'Scan Details';

const ScanDetailsRoutes = () => {
  const { scanId = '' } = useParams<{ scanId: string }>();

  const { setError } = React.useContext(ErrorContext);
  const { scan } = globalQueries.useGetScan({ id: scanId }, {
    enabled: !!scanId,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  const [pageTitle, setPageTitle] = React.useState<string>('');

  React.useLayoutEffect(() => {
    if (!pageTitle) {
      setPageTitle(SCAN_DETAILS);
    }
  }, [scanId]);

  return (
    <Routes>
      <Route path={allSubroutesEnum.Root} element={<Navigate to='findings' replace />} />
      <Route
        path={allSubroutesEnum.ScanDetailsFindings}
        element={
          <ScanDetails
            tabId='findings'
          >
            <ScanDetailsFindings scanId={scanId} />
          </ScanDetails>
        }
      />
      <Route path={allSubroutesEnum.ScanDetailsIssueKind} element={<IssueDetails scanId={scanId} />} />
      {/* <Route path={allSubroutesEnum.ScanDetailsSharing} element={<IssueSinglePage />} /> */}
      <Route
        path={allSubroutesEnum.ScanDetailsPaths}
        element={
          <ScanDetails
            tabId='paths'
          >
            <ScanDetailsPaths scanId={scanId} />
          </ScanDetails>
        }
      />
      <Route
        path={allSubroutesEnum.ScanDetailsChecks}
        element={
          <ScanDetails
            tabId='checks'
          >
            <ScanDetailsChecks scanId={scanId} />
          </ScanDetails>
        }
      />
      <Route
        path={allSubroutesEnum.ScanDetailsLoginStatus}
        element={
          <ScanDetails
            tabId='login-status'
          >
            <ScanDetailsLoginStatus scanId={scanId} />
          </ScanDetails>
        }
      />
      <Route
        path={allSubroutesEnum.ScanDetailsLogs}
        element={
          <ScanDetails
            tabId='logs'
          >
            <ScanDetailsLogs scanId={scanId} />
          </ScanDetails>
        }
      />
      <Route
        path={allSubroutesEnum.ScanParameters}
        element={
          <ScanDetails
            tabId='scan-parameters'
          >
            <ScanParameters scan={scan} />
          </ScanDetails>
        }
      />
      <Route
        path={allSubroutesEnum.ScanDetailsReports}
        element={
          <ScanDetails
            tabId='reports'
          >
            <ScanDetailsReports scan={scan} />
          </ScanDetails>
        }
      />
      <Route path='*' element={<NotFoundErrorWrapper />} />
    </Routes>
  );
};

export default ScanDetailsRoutes;
