import { allRoutesEnum } from '@shared/duck';
import { allSubroutesEnum } from 'pages/all-subroutes-enum';

const SCAN_DETAILS = 'Scan Details';
const PROJECT_DETAILS = 'Project Details';

export const BREADCRUMBS_NAME_MAP: { [key: string]: string } = {
  [allRoutesEnum.Home]: 'Home',

  [allRoutesEnum.NewScan]: 'New Scan',

  [allRoutesEnum.Scans]: 'Scans',
  [allRoutesEnum.ScanDetails + allSubroutesEnum.ScanDetailsFindings]: SCAN_DETAILS,
  [allRoutesEnum.ScanDetails + allSubroutesEnum.ScanDetailsChecks]: SCAN_DETAILS,
  [allRoutesEnum.ScanDetails + allSubroutesEnum.ScanDetailsLoginStatus]: SCAN_DETAILS,
  [allRoutesEnum.ScanDetails + allSubroutesEnum.ScanDetailsPaths]: SCAN_DETAILS,
  [allRoutesEnum.ScanDetails + allSubroutesEnum.ScanDetailsReports]: SCAN_DETAILS,
  [allRoutesEnum.ScanDetails + allSubroutesEnum.ScanDetailsLogs]: SCAN_DETAILS,
  [allRoutesEnum.ScanDetails + allSubroutesEnum.ScanParameters]: SCAN_DETAILS,
  [allRoutesEnum.ScanDetailsIssueKind]: 'Vulnerabilities',
  [allRoutesEnum.ScanDetailsSharing]: 'Issue Details',

  [allRoutesEnum.Targets]: 'Targets',
  [allRoutesEnum.TargetDetails]: 'Target Details',

  [allRoutesEnum.Projects]: 'Projects',
  [allRoutesEnum.ProjectDetails + allSubroutesEnum.ProjectTargets]: PROJECT_DETAILS,
  [allRoutesEnum.ProjectDetails + allSubroutesEnum.ProjectAuthentications]: PROJECT_DETAILS,
  [allRoutesEnum.ProjectDetails + allSubroutesEnum.ProjectCollaborators]: PROJECT_DETAILS,
  [allRoutesEnum.ProjectTargetDetails]: 'Target Details',
  [allRoutesEnum.ProjectAuthenticationDetails]: 'Authentication Details',

  [allRoutesEnum.Authentications]: 'Authentications',
  [allRoutesEnum.AuthenticationDetails]: 'Authentication Details',
  [allRoutesEnum.Profile]: 'Settings',
  [allRoutesEnum.Settings]: 'Settings',
};