import { FindingsTable } from './components';
import React from 'react';
import { SCAN_REFETCH_INTERVAL } from '../duck';
import { globalQueries } from '@shared/duck';
import { ScanStatusEnum } from 'models/Enums';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Stack } from '@mui/material';

interface ScanDetailsFindingsProps {
  scanId: string;
}

const ScanDetailsFindings: React.FC<ScanDetailsFindingsProps> = ({
  scanId,
}) => {
  const { setError } = React.useContext(ErrorContext);
  const { scan } = globalQueries.useGetScan({ id: scanId }, {
    refetchInterval: !scanId ? SCAN_REFETCH_INTERVAL : undefined,
    onError: err => errorResponseHandler(err, 'scan', setError),
  });

  const [scanEnded, setScanEnded] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (scan) {
      const scanInProgress = scan.status == ScanStatusEnum.IN_PROGRESS;
      setScanEnded(!scanInProgress);
    }
  }, [scan, scan?.status]);

  return (
    <Stack overflow='hidden' gap='0'>
      <FindingsTable
        scanId={scanId}
        scanEnded={scanEnded}
        refetchInterval={!scan?.ended_at ? SCAN_REFETCH_INTERVAL : undefined}
      />
    </Stack>
  );
};

export default ScanDetailsFindings;
