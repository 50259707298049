import { MuiProjectSelect } from '@shared/components';
import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';

const ProjectSelect: React.FC = ({
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext<formDataTypes.FormDataFormValues>();
  const onChangeProject = (e: any) => {
    setFieldValue('project', e.target.value);
    if (values.project?.id !== e.target.value?.id) {
      setFieldValue('target', null);
      setFieldValue('baseUrl', null);
      setFieldValue('authentication', null);
      setFieldValue('newTargetName', null);
      setFieldValue('createNewTargetCheckbox', false);
      setFieldValue('file', null);
      setFieldValue('nameExists', false);
    }
  };

  return (
    <MuiProjectSelect
      label=''
      defaultProject={values.project}
      onFormChange={onChangeProject}
      {...props}
    />
  );
};

export default ProjectSelect;