import { CredentialsRef, CredentialsType, Target, User } from '@api-client';
import { Box, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { tableTypes, UILink, UISeverityDots, UIUserAvatar } from '@shared/components';
import { allRoutesEnum, globalEnums, globalModels, globalUtils } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { DisruptedIcon } from 'views/components/v1/Icons';
import { formatTimeSpan } from 'views/utils/dates';
import useTimer from 'views/utils/useTimer';

export const recentScansColumns: tableTypes.ColumnProps<globalModels.ScansViewModelNew>[] = [
  {
    title: 'Target',
    columnKey: 'targetName',
    minWidth: '15.75rem',
    cellInfo: {
      getIcon(item) {
        return (
          <Stack position='relative'>
            {globalUtils.getTargetTypeIcon(
              item.targetType.toString()?.toUpperCase() as globalEnums.ETargetType,
              item.isInProgress,
            )}
            {item.isDisrupted && (
              <Tooltip
                arrow
                title='This scan was disrupted'
                placement='top'
              >
                <Stack
                  sx={{ position: 'absolute', top: '-5px', left: '-5px' }}
                >
                  <DisruptedIcon size='tiny' />
                </Stack>
              </Tooltip>
            )}
          </Stack>
        );
      },
      description(item) {
        const { time: currentTime } = useTimer({
          fromDate: item.startedAt,
          formatter: formatTimeSpan,
          isActive: item.isInProgress,
        });

        return (
          item.isDisrupted && item.completedIn
            ?
            'Disrupted' + ' | ' + `Completed in: ${formatTimeSpan(Math.round(item.completedIn))}`
            :
            item.completedIn && !item.isInProgress
              ? `Completed in: ${formatTimeSpan(Math.round(item.completedIn))}`
              :
              item.isInProgress ? 'In Progress' + ' | ' + `${item.percentage}%` + ' | ' + `${currentTime}` : ''
        );
      },
      href(item) {
        return allRoutesEnum.ScanDetails.replace(':scanId', item.id);
      }
    },
    customCellRenderer(value) {
      return (
        <Typography variant='subtitle2' fontWeight='600'>
          {value as string}
        </Typography>
      );
    },
  },
  {
    title: 'Authentication',
    columnKey: 'credentials',
    minWidth: '12.75rem',
    cellInfo: {
      description(item) {
        return (
          item.credentials?.type ?
            globalUtils.getAuthenticationTypeName(item.credentials.type as CredentialsType) :
            'N/A'
        );
      },
    },
    customCellRenderer(value, item) {
      const credentials = value as CredentialsRef | undefined;
      const credentialsName = credentials?.name || '';
      const credentialsLink =
        (
          credentials?.id && allRoutesEnum.AuthenticationDetails.replace(':authenticationId', credentials.id)
        ) || '';

      const isDeleted = credentials?.is_deleted;
      const isCredFromAnotherProject = credentials?.project !== item.target?.project;

      return (
        <Box>
          {credentialsLink ? (
            isDeleted ? (
              <Stack gap='0.25rem' direction='row'>
                <Typography variant='subtitle2' color={muiPalette.grey?.[600]}>
                  {credentialsName}
                </Typography>
                <Typography variant='subtitle2' color={muiPalette.red?.[600]} fontWeight='400'>
                  (Deleted)
                </Typography>
              </Stack>
            ) : (
              isCredFromAnotherProject ? (
                <Typography variant='subtitle2' fontWeight='600'>
                  {credentialsName}
                </Typography>
              ) : (
                <UILink
                  href={credentialsLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  text={credentialsName}
                />
              )
            )
          ) : (
            'N/A'
          )}
        </Box>
      );
    },
  },
  {
    title: 'Vulnerable Paths',
    columnKey: 'vulnPathsStat',
    minWidth: '14.1875rem',
    customCellRenderer(value, item) {
      return (
        <UISeverityDots values={item.vulnPathsStat} inProgress={item.isInProgress} popupComments='paths' />
      );
    },
  },
  {
    title: 'Accessibility',
    columnKey: 'target',
    width: '6.5625rem',
    customCellRenderer(value) {
      const color = (value as Target).internet_accessible === false ? 'primary' : 'secondary';
      const label = (value as Target).internet_accessible === false ? 'Private' : 'Public';
      return (
        <Chip color={color} label={label} />
      );
    },
  },
  {
    title: 'Initiated By',
    columnKey: 'createdBy',
    width: '4.4375rem',
    cellInfo: {
      alignItems: 'left',
    },
    customCellRenderer(createdBy, item, index) {
      const user = createdBy as Partial<User> | undefined;
      return (
        <UIUserAvatar
          src={user?.avatar_url}
          tooltipId={user?.id ? (
            user.id + index
          ) : index.toString()}
          tooltipText={user?.first_name + ' ' + user?.last_name}
        />
      );
    },
  },
];