import React from 'react';
import {
  InstallCLITabs,
  UIRightSideModal,
  ModalContent,
  ModalHeader,
  UICodeFragment,
  DocsLink,
  MuiAuthenticationSelect,
} from '@shared/components';
import { Note } from '@contentful/f36-components';
import { globalEnums, globalConstants } from '@shared/duck';
import { IFormControlEvent } from 'views/utils/form';
import { InfoCircleIcon } from 'views/components/v1/Icons';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Tooltip, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';
import { Credentials } from '@api-client';

interface UnableToRunScanModalProps {
  type: globalEnums.ETargetTypes;
  openModal: boolean;
  closeModal: () => void;
  targetName?: string;
  projectId?: string;
}

const UnableToRunScanModal: React.FC<UnableToRunScanModalProps> = ({
  type,
  openModal,
  closeModal,
  targetName = 'http://testphp.vulwneb.com',
  projectId = '',
}) => {
  const [authentication, setAuthentication] = React.useState<Credentials>();
  const onAuthenticationChange = (e: IFormControlEvent<Credentials | undefined>) => setAuthentication(e.target.value);

  const code = React.useMemo(() => {
    return (
      '# Run a scan:' + '\n' +
      `nightvision scan ${targetName} \\\n` +
      `-P ${projectId} ` +
      (
        authentication ? `\\\n--auth ${authentication.name}` : ''
      )
    );
  }, [authentication]);

  return (
    <UIRightSideModal
      isShown={openModal}
      onClose={closeModal}
    >
      <ModalHeader onClose={closeModal} title='Unable to run scan directly...' />
      <ModalContent style={{ display: 'flex', flexDirection: 'column', padding: '1.5rem', maxHeight: 'unset' }}>
        <Stack gap='1.5rem'>
          <Typography variant='default' color={muiPalette.grey?.[900]}>
            To run this scan from CLI, select the Authentication
          </Typography>
          <Stack direction='row' gap='1rem'>
            <Stack gap='0' width='100%'>
              <Stack direction='row' alignItems='center'>
                <Typography variant='default' fontWeight='500' mb='0.5rem'>Authentication (Optional)</Typography>
                <Tooltip title={globalConstants.AUTHENTICATION_TOOLTIP}>
                  <Box>
                    <InfoCircleIcon
                      variant={globalEnums.EIconVariants.MUTED}
                      size='small'
                    />
                  </Box>
                </Tooltip>
              </Stack>
              <Box width='100%'>
                <MuiAuthenticationSelect
                  label=''
                  currentValue={authentication}
                  projectId={projectId}
                  onFormChange={onAuthenticationChange}
                />
              </Box>
            </Stack>
          </Stack>

          <Stack gap='1rem' pt='0.75rem'>
            <Typography variant='default' color={muiPalette.grey?.[900]}>
              Use the following command in your CLI
            </Typography>
            <UICodeFragment
              code={code}
              language={globalEnums.SupportedLangs.evidence_markup}
            />
          </Stack>

          <Note variant={globalEnums.ENoteVariants.PRIMARY}>
            {'Make sure you have NightVision CLI installed & your environment has network access to the application.'}
          </Note>


          {type === globalEnums.ETargetTypes.OPEN_API && (
            <>
              {/* <Text fontSize={'fontSizeL'}>
                         {'Option 1.'}&nbsp;
                         </Text> */}
              <Typography variant='default'>
                {'Here’s an example that shows how to scan an extracted Swagger spec:'}
              </Typography>

              <UICodeFragment
                code={
                  '# Using an extracted Swagger spec\n' +
                  'git clone https://github.com/vulnerable-apps/javaspringvulny.git\n' +
                  'cd javaspringvulny\n' +
                  '\n' +
                  '# Create an app to manage paths/urls exclusion\n' +
                  'nightvision app create javaspringvulny\n' +
                  '\n' +
                  '# Start the application\n' +
                  'docker-compose up -d: sleep 10\n' +
                  '\n' +
                  '# Create a target\n' +
                  'nightvision target create \\\n' +
                  'javaspringvulny \\\n' +
                  'https://localhost:9000 \\\n' +
                  `--type ${globalEnums.ECLITargetType.API}\n` +
                  '\n' +
                  '# Extract API documentation\n' +
                  'nightvision swagger extract ./ \\\n' +
                  '-t javaspringvulny \\\n' +
                  '# Choose the right language for your target\n' +
                  '--lang spring\n' +
                  '\n' +
                  '# Record authentication - click on Form Auth\n' +
                  '# Authentication - username: user; password: password\n' +
                  'nightvision auth playwright create \\\n' +
                  'javaspringvulny \\\n' +
                  'https://localhost:9000\n' +
                  '\n' +
                  '# Run the scan. It will automatically select the Swagger spec\n' +
                  'nightvision scan javaspringvulny \\\n' +
                  '--auth javaspringvulny'
                }
                language={globalEnums.SupportedLangs.bash}
              />
            </>
          )}
        </Stack>
        {/* TODO: add 2 option */}
        {/* <Stack style={{ padding: '1rem 0' }}>
          <Text fontSize={'fontSizeL'}>
          {'Option 2.'}&nbsp;
          </Text>
          <Text>
          {'Here’s an example that shows how to scan an uploaded Swagger spec'}
          </Text>
          </Stack>

          <CodeFragment
          code={
          '# Using an uploaded Swagger spec\n' +

          '# Run the scan. It will automatically select the Swagger spec\n' +
          'nightvision scan https://localhost:9000 {globalEnums.ECLITargetType.API}'
          }
          language={globalEnums.SupportedLangs.evidence_markup}
          /> */
        }
        <Box p='1rem 0'>
          <Accordion>
            <AccordionSummary>
              <Typography fontWeight='500'>Install CLI</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InstallCLITabs />
            </AccordionDetails>
          </Accordion>
        </Box>

        <Typography variant='default' display='flex'>
          <>
            {(
              () => {
                switch (type) {
                  case globalEnums.ETargetTypes.WEB:
                    return (
                      <>
                        {'Learn more about CLI Web Scans in our'}&nbsp;
                      </>
                    );
                  case globalEnums.ETargetTypes.OPEN_API:
                    return (
                      <>
                        {'Learn more about CLI Open API Scans in our'}&nbsp;
                      </>
                    );
                }
              }
            )()}
          </>

          <DocsLink
            to={globalConstants.DOCS_PATHS.CLI_CHEAT_SHEET_SCANNING}
          >
            {'documentation'}
          </DocsLink>
        </Typography>
      </ModalContent>
    </UIRightSideModal>
  );
};

export default UnableToRunScanModal;