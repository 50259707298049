import React from 'react';
import { Button, Stack } from '@mui/material';
import { AplliedFilterChip } from '../applied-filter-chip';
import { ScanFiltersContext } from '../../filters-context';
import { FilterName } from '../../duck';

export const SelectedFiltersPanel: React.FC = () => {
  const {
    appliedFilters, filterDictionary
  } = React.useContext(ScanFiltersContext);

  const isAnyFilterApplied = appliedFilters?.length;

  const resetAllFilters = () => {
    filterDictionary[FilterName.TARGET]?.setSelected([], filterDictionary[FilterName.TARGET].selected, 'removeOption');
    filterDictionary[FilterName.PROJECT]?.setSelected([], filterDictionary[FilterName.PROJECT].selected, 'removeOption');
    filterDictionary[FilterName.AUTHENTICATION]?.setSelected([], filterDictionary[FilterName.AUTHENTICATION].selected, 'removeOption');
    filterDictionary[FilterName.ACCESSIBILITY]?.setSelected([], filterDictionary[FilterName.ACCESSIBILITY].selected, 'removeOption');
    filterDictionary[FilterName.INITIATEDBY]?.setSelected([], filterDictionary[FilterName.INITIATEDBY].selected, 'removeOption');
    filterDictionary[FilterName.STARTDATE]?.setSelected([], filterDictionary[FilterName.STARTDATE].selected, 'removeOption');
    filterDictionary[FilterName.ENDDATE]?.setSelected([], filterDictionary[FilterName.ENDDATE].selected, 'removeOption');
    filterDictionary[FilterName.STATUS]?.setSelected([], filterDictionary[FilterName.STATUS].selected, 'removeOption');
  };

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [showOverflowIndicator, setShowOverflowIndicator] = React.useState(false);
  const [lastIndex, setLastIndex] = React.useState(-1);

  const checkOverflow = () => {
    const container = containerRef.current;
    if (container) {
      setLastIndex(-1);
      const containerTop = container.getBoundingClientRect().top;
      const children = Array.from(container.children) as HTMLElement[];
      setShowOverflowIndicator(false);
      let isSet = false;
      children.forEach((child, index) => {
        const childTop = child.getBoundingClientRect().top;
        if (childTop > containerTop + container.clientHeight + 4 && !isSet) {
          setShowOverflowIndicator(true);
          isSet = true;
          setLastIndex(index - 1);
        }
      });
    }

  };
  React.useEffect(() => {
    checkOverflow();
  }, [appliedFilters]);

  React.useEffect(() => {
    if (lastIndex > 0) {
      checkOverflow();
    }
  }, [lastIndex]);

  React.useEffect(() => {
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    Boolean(isAnyFilterApplied) && (
      <Stack alignItems='flex-start'>
        <Stack direction='row' flexWrap='wrap' gap='0.5rem' alignItems='center' ref={containerRef} maxHeight='51px' overflow='hidden'>
          {appliedFilters?.map((filter, index) => (
            <AplliedFilterChip
              filter={filter.filterName}
              name={filter.value}
              id={filter.id}
              icon={filter.icon}
              onDelete={() => {
                const currentFilter = filterDictionary[filter.filterName];

                const deletedItem = currentFilter?.selected
                  .find((item: any) => currentFilter.keyBy(item) === filter.id.substring(filter.filterName.length + 1));
                const newValues = currentFilter?.selected
                  .filter((item: any) => currentFilter?.keyBy(item) !== filter.id.substring(filter.filterName.length + 1)) || [];
                const changedOptions = deletedItem ? [deletedItem] : [];

                if (deletedItem) {
                  currentFilter?.setSelected?.(newValues, changedOptions, 'removeOption');
                }
              }}
              key={`filter-chip-${filter.id}`}
              indicator={(lastIndex === index) && showOverflowIndicator}
              count={appliedFilters.length - index - 1}
              tooltip={appliedFilters.slice(lastIndex + 1).map(item => `${item.filterName}: ${item.value}`).join(', ')}
              onDeleteAllFromIndex={() => {
                const forDelete = appliedFilters.slice(lastIndex + 1);
                const res = Object.groupBy(forDelete, ({ filterName }) => filterName);
                Object.keys(res).forEach(key => {
                  const currentFilter = filterDictionary[key as FilterName];

                  const deletedItems = currentFilter?.selected.filter((item: any) =>
                    forDelete.some(op => op.id.substring(key.length + 1) === currentFilter.keyBy(item))) || [];
                  const newValues = currentFilter?.selected.filter((item: any) =>
                    !forDelete.some(op => op.id.substring(key.length + 1) === currentFilter.keyBy(item))) || [];

                  currentFilter?.setSelected?.(newValues, deletedItems, 'removeOption');
                });
              }}
            />
          ))}
        </Stack>
        <Button variant='text' size='small' onClick={resetAllFilters} sx={{ p: 0 }}>
          Clear all
        </Button>
      </Stack>
    )
  );
};