import { MenuType } from './types';
import { allRoutesEnum, globalConstants } from '@shared/duck';
import {
  NavAuthenticationIcon,
  NavDocumentationIcon,
  NavHomeIcon,
  NavProjectsIcon,
  NavScansIcon,
  NavTargetsIcon,
} from 'views/components/v1/Icons';

export const MENU: MenuType[] = [
  {
    title: globalConstants.PAGES.home,
    to: allRoutesEnum.Home,
    icon: NavHomeIcon,
  },
  {
    title: globalConstants.PAGES.scans,
    to: allRoutesEnum.Scans,
    icon: NavScansIcon,
  },
  {
    title: globalConstants.PAGES.targets,
    to: allRoutesEnum.Targets,
    icon: NavTargetsIcon,
  },
  {
    title: globalConstants.PAGES.authentications,
    to: allRoutesEnum.Authentications,
    icon: NavAuthenticationIcon,
  },
  {
    title: globalConstants.PAGES.projects,
    to: allRoutesEnum.Projects,
    icon: NavProjectsIcon,
  },
  {
    title: 'Documentation',
    to: allRoutesEnum.Documentation,
    icon: NavDocumentationIcon,
  },
  // {
  //   key: globalConstants.PAGES.customizeNuclei,
  //   to: globalUtils.withSubRoutes(allRoutesEnum.NucleiTemplates),
  //   icon: NavNucleiIcon,
  // },
];