import { tableTypes, FormikControl, targetExclusionsUtils } from '@shared/components';
import * as Yup from 'yup';
import { DeleteCellRender, NameCellRender, UrlCellRender } from '../components';
import { globalConstants, globalModels, globalQueries, globalUtils } from '@shared/duck';
import { UrlCellRenderForEdit } from '../components/url-column';
import styled from '@emotion/styled';
import { TargetsValues, WebTargetsContentValues } from './types';
import { enqueueSnackbar } from 'notistack';
import { GitHubTargetConfiguration } from '@api-client';

const StyledTableHeader = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem; 
  letter-spacing: -0.00963rem;
`;

export const CreateWebTargetsModalValuesSchema = Yup.object().shape({
  targets: Yup.array()
    .when('firstPage', {
      is: false,
      then: schema =>
        schema.of(
          Yup.object().shape({
            name: Yup.string().when('exists', {
              is: true,
              then: schema => schema.test('exists', 'This name is already taken in this Project', () => false),
              otherwise: schema => schema.required('Please enter target name').max(100, 'Name must be at most 100 characters')
                .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
                  return globalUtils.validateName(value || '');
                }),
            }),
            isNameTested: Yup.boolean().oneOf([true], 'Name must be tested').required('Please test the name'),
            isAccessTested: Yup.boolean().oneOf([true], 'Url must be tested').required('Please test the url'),
            url: Yup.string().required('Please enter target url').test('isValid', 'Invalid format', (value?: string) => {
              const newUrls = value?.trim();
              return globalUtils.validateUrls([newUrls || '']);
            }),
          }),
        ),
    }),
  firstPage: Yup.boolean(),
  stringUrl: Yup.string().required('Target url is required')
    .test('isValid', 'Invalid format', (value?: string) => {
      const newUrls = value?.split('\n').filter(url => url.length);
      return globalUtils.validateUrls(newUrls || []);
    }),
  exclusions: targetExclusionsUtils.TargetExclusionsValuesSchema,
  project: Yup.mixed().test('projects-field', 'Please select a project', (projectId: string) => {
    return !!projectId;
  }),
});

export const getTableProps = (
  values: WebTargetsContentValues,
  isInEdit: boolean,
  project?: string,
): tableTypes.BasicTableProps<TargetsValues> => {
  const columns: tableTypes.ColumnProps<TargetsValues>[] = [
    {
      title: 'Name',
      columnKey: 'name',
      customHeadRenderer() {
        return (
          <StyledTableHeader>Target name</StyledTableHeader>
        );
      },
      customCellRenderer(_, __, index) {
        return (
          <FormikControl name={`targets[${index}].name`} style={{ marginBottom: '0' }} tooltipError showError>
            <NameCellRender name={`targets[${index}].name`} index={index} project={project} isInEdit={isInEdit} />
          </FormikControl>
        );
      },
      cellInfo: {
        withoutPadding: true,
        fullWidth: true,
      },
      width: '19rem',
    },
    {
      title: 'URL',
      columnKey: 'url',
      customHeadRenderer() {
        return (
          <StyledTableHeader>URL</StyledTableHeader>
        );
      },
      customCellRenderer(_, __, index) {
        return (
          <FormikControl name={`targets[${index}].url`} style={{ marginBottom: '0' }} tooltipError showError id={`${index}`}>
            {isInEdit ? <UrlCellRenderForEdit name={`targets[${index}]`} index={index} ></UrlCellRenderForEdit> :
              <UrlCellRender name={`targets[${index}]`} index={index} projectId={project} />}
          </FormikControl>
        );
      },
      cellInfo: {
        withoutPadding: true,
        fullWidth: true,
      },
      width: '19rem',
    },
  ];
  if (!isInEdit) {
    // Show Delete column only in Create
    columns.unshift(
      {
        title: 'Delete',
        columnKey: 'id',
        customCellRenderer(_, __, index) {
          return (
            <DeleteCellRender index={index} valuesLength={values.targets.length} />
          );
        },
        customHeadRenderer() {
          return <></>;
        },
        cellInfo: {
          withoutPadding: true,
          fullWidth: true,
        },
        width: '0.625rem',
      });
  }
  const tableConfig: tableTypes.BasicTableProps<TargetsValues> = {
    data: values.targets,
    columns,
    bordered: true,
    withRowSelection: false,
  };
  return tableConfig;
};

export const getNameFromUrl = (url: string): string => {
  let newUrl = '';
  if (url.indexOf('://') == -1) {
    newUrl = url;
  } else
    newUrl = url.substring(url.indexOf('://') + 3, url.length);
  const parts = newUrl.includes('127.0.0.1') ? newUrl.split(/[/]+/) : newUrl.split(/[./]+/);
  return (parts[0] === 'www' ? parts[1] : parts[0]).replaceAll(/[@#:*?.]+/g, '-');
};

const getErrorMessage = (error: any): string | undefined => {
  const response = error.response?.data['errors'][0];
  if (response.code === 'invalid') {
    return response.detail.toString();
  }
};

interface GetUtilsProps {
  target?: globalModels.TargetViewModel;
  project?: string;
  onAfterUpdate?: (values: WebTargetsContentValues) => void;
  onAfterCreate?: (values: WebTargetsContentValues) => void;
  appConfigToTarget: boolean;
}

interface GetUtilsResultProps {
  onUpdateTargets: (values: WebTargetsContentValues) => void;
  onCreateTargets: (values: WebTargetsContentValues) => void;
  getInitialValues: () => WebTargetsContentValues;
}

export const useGetWebUtils = ({
  target,
  project,
  onAfterUpdate,
  onAfterCreate,
}: GetUtilsProps): GetUtilsResultProps => {
  const { updateUrlTargets } = globalQueries.useUpdateTargetUrl();
  const { createUrlTargets } = globalQueries.useCreateTargetUrl();

  const onUpdateTargets = async (values: WebTargetsContentValues) => {
    try {
      const configuration: GitHubTargetConfiguration = {
        excluded_url_patterns: values.configuration.excluded_url_patterns?.split('\n'),
        excluded_x_paths: values.configuration.excluded_x_paths?.split('\n'),
      };
      const isExclusionsChanged = JSON.stringify(target?.configuration) !== JSON.stringify(values.configuration);

      const response = await updateUrlTargets({
        id: target?.id || '',
        urlsTargetUpdateRequest:
        {
          location: values.targets[0].url,
          name: values.targets[0].name,
          credentials_id: null,
          configuration: isExclusionsChanged ? configuration : undefined,
        },
      });

      const newTargets: TargetsValues[] = values.targets.map(target => ({ ...target, id: response.data.id }));
      onAfterUpdate?.({ ...values, targets: newTargets });
      enqueueSnackbar('Web Target has been updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to update Web Target', { variant: 'error' });
    }
  };

  const onCreateTargets = async (values: WebTargetsContentValues) => {
    try {
      const configuration: GitHubTargetConfiguration = {
        excluded_url_patterns: values.configuration.excluded_url_patterns?.split('\n'),
        excluded_x_paths: values.configuration.excluded_x_paths?.split('\n'),
      };

      const response = await createUrlTargets({
        urlsTargetRequest: values.targets.map(target => (
          {
            location: target.url,
            name: target.name,
            credentials_id: null,
            project: project,
            internet_accessible: target.isAccessible,
            configuration: configuration,
          }
        )),
      });

      const newTargets: TargetsValues[] = values.targets.map((target, i) => ({ ...target, id: response.data[i].id }));
      onAfterCreate?.({ ...values, targets: newTargets });
      enqueueSnackbar('Web Target(s) have been created successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error) ?? 'Failed to create Web Target(s)', { variant: 'error' });
    }
  };

  const getInitialValues = () => {
    return {
      targets: target
        ? [{
          name: target.name, url: target.location,
          isAccessTested: false, isNameTested: true, isAccessible: false
        } as TargetsValues]
        : [],
      stringUrl: target?.location || '',
      firstPage: !target,
      project: project,
      configuration: {
        excluded_url_patterns: target?.configuration?.excluded_url_patterns?.join('\n'),
        excluded_x_paths: target?.configuration?.excluded_x_paths?.join('\n'),
      },
    };
  };

  return {
    onUpdateTargets,
    onCreateTargets,
    getInitialValues,
  };
};