import dayjs from 'dayjs';
import React from 'react';
import { recentScansTableConsts, recentScansTableHooks } from './duck';
import { tableTypes, MuiTable } from '@shared/components';
import { allRoutesEnum, globalConstants, globalModels, globalTypes } from '@shared/duck';
import { ScansGray } from '@assets';
import { useNavigate } from 'react-router-dom';

interface RecentScansTableProps {
  filter?: string;
  createdSince?: string;
  createdBefore?: string;
  limit?: number;
  selectedProjects: globalTypes.UIOptionItem<string>[];
}

const RecentScansTable: React.FC<RecentScansTableProps> = ({
  filter,
  createdSince,
  createdBefore,
  limit,
  selectedProjects,
}) => {
  const navigate = useNavigate();

  const {
    scans,
    isScansListLoading,
  } = recentScansTableHooks.useRecentScansTableApi(
    {
      filter: filter,
      createdSince: createdSince,
      createdBefore: createdBefore,
      project: selectedProjects.map(proj => proj.value),
      limit: limit,
      page: 0,
      pageSize: 5,
    },
  );

  const scansConfig: tableTypes.MuiTableProps<globalModels.ScansViewModelNew> = {
    data: scans,
    columns: recentScansTableConsts.recentScansColumns,
    withExternalBorders: false,
    loading: isScansListLoading,
    isLoadMoreAvailable: false,
    onRowClick: (item) => {
      const url = allRoutesEnum.ScanDetails.replace(':scanId', item.id);
      navigate(url);
    },
    noContentMessage: 'No Scans found',
    noContentIcon: ScansGray,
    groupRowsBy: (item: globalModels.ScansViewModelNew) => dayjs(item.createdAt).format(globalConstants.FULL_MONTH_DATE),
  };

  return (
    <MuiTable
      {...scansConfig}
    />
  );
};

export default RecentScansTable;
