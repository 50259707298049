import React from 'react';
import { Description, Title } from './cli-commands.styled';
import { UICodeFragment } from '@shared/components';
import { SupportedLangs } from '@shared/duck/enums/global-enums';
import { useFormikContext } from 'formik';
import { formDataTypes } from '../../../components';
import { globalEnums } from '@shared/duck';

const CliCommands: React.FC = () => {
  const { values } = useFormikContext<formDataTypes.FormDataFormValues>();
  const initTargetName = values?.target?.name;
  const initTargetUrl = values?.target?.location;
  const newTargetName = values?.newTargetName;
  const newTargetUrl = values?.baseUrl;
  const projectId = values?.project?.id;

  const isTargetNameChanged = !values?.createNewTargetCheckbox && newTargetName && (initTargetName !== values?.newTargetName);
  const isTargetLocationChanged = !values?.createNewTargetCheckbox && (initTargetUrl !== newTargetUrl);
  const isNewTarget = values.createNewTargetCheckbox;
  let step = 1;

  return (
    <Description variant='caption'>
      {isNewTarget && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'Let’s create a new target first.'}
          <UICodeFragment code={`# Create target\nnightvision target create ${newTargetName} ${newTargetUrl} -P ${projectId} --type ${globalEnums.ECLITargetType.WEB}`} language={SupportedLangs.bash} />
          <br />
        </>
      )}

      {(isTargetNameChanged || isTargetLocationChanged) && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'We have noticed that you made changes to the target; let\'s apply those first:'}
          <UICodeFragment code={`# Update target\nnightvision target update ${initTargetName}${isTargetNameChanged ? ` -n ${newTargetName}` : ''}${isTargetLocationChanged ? ` -u ${newTargetUrl}` : ''} -P ${projectId}`} language={SupportedLangs.bash} />
          <br />
        </>
      )}

      <Title>{step === 1 ? <br /> : `Step ${step++}`}</Title>
      {'Run scan:'}
      <UICodeFragment code={`# Run scan\nnightvision scan ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} -P ${projectId} ${values?.authentication ? `--auth ${values?.authentication.name}` : ''}`} language={SupportedLangs.bash} />
    </Description>
  );
};

export default CliCommands;