import React from 'react';
import {
  UIDialogTitle
} from '@shared/components';
import { globalModels } from '@shared/duck';
import { Dialog } from '@mui/material';
import { useGlobalModalContext } from '@shared/duck/contexts';
import { TargetModalContent } from './components';
import { useGetInitialValues } from './duck';

export interface CreateOrEditTargetsModalProps {
  target?: globalModels.TargetViewModel;
  enableProjectSelect?: boolean;
}

export const CreateOrEditTargetsModal: React.FC<CreateOrEditTargetsModalProps> = ({
  target,
  enableProjectSelect,
}) => {
  const {
    defaultProject,
    isDefaultProjectLoading,
  } = useGetInitialValues();

  const [isShown, setIsShown] = React.useState<boolean>(true);
  const closeModal = () => setIsShown(false);
  const { hideModal } = useGlobalModalContext();

  return (
    <Dialog
      open={isShown}
      onClose={closeModal}
      onTransitionExited={hideModal}
      fullWidth
      scroll='paper'
      sx={{
        '.MuiPaper-root': {
          maxWidth: '43rem',
        }
      }}
    >
      <UIDialogTitle
        title={target ? 'Edit Target' : 'Add new Target'}
        onCloseBtnAction={closeModal}
      />
      <TargetModalContent
        target={target}
        enableProjectSelect={enableProjectSelect}
        isDefaultProjectLoading={isDefaultProjectLoading}
        defaultProject={defaultProject}
        closeModal={closeModal}
      />
    </Dialog>
  );
};