import { allSubroutesEnum } from 'pages/all-subroutes-enum';
import { AuthenticationDetails } from 'pages/workspace-router/authentications/components';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProjectDetails from './project-details';
import { ProjectAuthentications, ProjectCollaborators, ProjectTargets } from './components';
import { TargetDetailsPage } from '@shared/components';
import { NotFoundErrorWrapper } from '@pages';

const ProjectDetailsRoutes = () => {

  return (
    <Routes>
      <Route path={allSubroutesEnum.Root} element={<Navigate to='targets' replace />} />
      <Route
        path={allSubroutesEnum.ProjectTargets}
        element={
          <ProjectDetails tabId='targets'>
            <ProjectTargets />
          </ProjectDetails>
        }
      />
      <Route
        path={allSubroutesEnum.ProjectTargetDetails}
        element={<TargetDetailsPage />}
      />
      <Route
        path={allSubroutesEnum.ProjectAuthentications}
        element={
          <ProjectDetails tabId='authentications'>
            <ProjectAuthentications />
          </ProjectDetails>
        }
      />

      <Route path={allSubroutesEnum.ProjectAuthenticationsDetails} element={<AuthenticationDetails />} />

      <Route
        path={allSubroutesEnum.ProjectCollaborators}
        element={
          <ProjectDetails tabId='collaborators'>
            <ProjectCollaborators />
          </ProjectDetails>}
      />
      <Route path='*' element={<NotFoundErrorWrapper />} />
    </Routes>
  );
};

export default ProjectDetailsRoutes;
