import React from 'react';
import { RescanPopupWrapper } from '@app/components/workspace/components';
import { RescanPopupWrapperProps } from '@app/components/workspace/components/rescan-popup-wrapper';
import { AuthenticationModal, AuthenticationModalProps } from '@shared/components/authentication-modal';
import { CreateOrEditTargetsModal, CreateOrEditTargetsModalProps } from '@shared/components/create-targets';
import { UIDeleteModal, DeleteModalProps, ShareProjectModal, ShareProjectModalProps } from '@shared/components/modals';
import { CreateProjectModal, CreateProjectModalProps } from '@shared/duck/utils/project-utils/modals';

// 1. Add new modal type
export enum MODAL_TYPE {
  None,
  DeleteModal,
  RescanPopupModal,
  AuthenticationModal,
  TargetModal,
  ProjectModal,
  ShareProjectModal,
}

// 2. Add modal type -> render function mapping
export const MODAL_COMPONENT_BY_TYPE: Record<string, React.FC<any> | undefined> = {
  [MODAL_TYPE.None]: () => null,
  [MODAL_TYPE.DeleteModal]: UIDeleteModal,
  [MODAL_TYPE.RescanPopupModal]: RescanPopupWrapper,
  [MODAL_TYPE.AuthenticationModal]: AuthenticationModal,
  [MODAL_TYPE.TargetModal]: CreateOrEditTargetsModal,
  [MODAL_TYPE.ProjectModal]: CreateProjectModal,
  [MODAL_TYPE.ShareProjectModal]: ShareProjectModal,
};

// 3. Add new *ModalInfo interface

interface NoModalInfo {
  modalType: MODAL_TYPE.None;
  modalProps: null;
}

interface DeleteModalInfo {
  modalType: MODAL_TYPE.DeleteModal;
  modalProps: DeleteModalProps;
}

interface RescanPopupModalInfo {
  modalType: MODAL_TYPE.RescanPopupModal;
  modalProps: RescanPopupWrapperProps;
}

interface AuthenticationModalInfo {
  modalType: MODAL_TYPE.AuthenticationModal;
  modalProps: AuthenticationModalProps;
}

interface TargetModalInfo {
  modalType: MODAL_TYPE.TargetModal;
  modalProps: CreateOrEditTargetsModalProps;
}

interface ProjectModalInfo {
  modalType: MODAL_TYPE.ProjectModal;
  modalProps: CreateProjectModalProps;
}

interface ShareProjectModalInfo {
  modalType: MODAL_TYPE.ShareProjectModal;
  modalProps: ShareProjectModalProps;
}

// 4. Register new *ModalInfo interface globally
export type GlobalModalInfo =
  | NoModalInfo
  | DeleteModalInfo
  | RescanPopupModalInfo
  | AuthenticationModalInfo
  | TargetModalInfo
  | ProjectModalInfo
  | ShareProjectModalInfo
  ;
