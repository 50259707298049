import React from 'react';
import {
  AppliedFiltersProps, FilterName, FilterOptions, SCAN_STATUSES, getItemsList,
  getUserAvatar, useCreateFilterOptionsFabric, useScansPageItems
} from './duck';
import { FilterDictionary, ScanFiltersContext } from './filters-context';
import ScansPage from './scans-page';
import { ScanStatus } from '@api-client';
import { useSearchParams } from 'react-router-dom';
import { globalModels } from '@shared/duck';
import { useLaunchDarklyFlags } from '@shared/hooks';

export interface UIOptionItemNew<T> {
  key?: string | number;
  label: string;
  value: T;
  extraInfo?: string;
}

export const ScansPageWrapper: React.FC = () => {
  const { scanPlanner } = useLaunchDarklyFlags();
  const { scanSelectionApi, footerItems } = useScansPageItems();

  const [selectedProjects, setSelectedProjects] = React.useState<UIOptionItemNew<string>[]>([]);
  const [selectedAuthentications, setSelectedAuthentications] = React.useState<UIOptionItemNew<string>[]>([]);
  const [selectedTargets, setSelectedTargets] = React.useState<UIOptionItemNew<string>[]>([]);
  const [selectedAccessibility, setSelectedAccessibility] = React.useState<UIOptionItemNew<number>[]>([]);
  const [selectedUsers, setSelectedUsers] = React.useState<UIOptionItemNew<string>[]>([]);
  const [startDate, setStartDate] = React.useState<UIOptionItemNew<string>[]>([]);
  const [endDate, setEndDate] = React.useState<UIOptionItemNew<string>[]>([]);
  const [selectedStatuses, setSelectedStatuses] = React.useState<UIOptionItemNew<ScanStatus[] | string>[]>([]);

  const [appliedFilters, setAppliedFilters] = React.useState<AppliedFiltersProps[]>([]);

  const [isOpenPlanner, setOpenPlanner] = React.useState<boolean>(false);
  const [selectedPlannerScans, setSelectedPlannerScans] = React.useState<globalModels.ScansViewModelNew[]>();

  const itemList = getItemsList({
    selectedStatuses: selectedStatuses,
    setSelectedStatuses: setSelectedStatuses,
    setAppliedFilters: setAppliedFilters,
    scanSelectionApi: scanSelectionApi,
    plannerData: { setOpenPlanner, setSelectedPlannerScans, scanPlanner }
  });

  const { createFilterOptions } = useCreateFilterOptionsFabric(setAppliedFilters);

  const filterDict: FilterDictionary<FilterName, FilterOptions> = React.useMemo(() => ({
    [FilterName.TARGET]: createFilterOptions(selectedTargets, setSelectedTargets, FilterName.TARGET),
    [FilterName.PROJECT]: createFilterOptions(selectedProjects, setSelectedProjects, FilterName.PROJECT),
    [FilterName.AUTHENTICATION]: createFilterOptions(selectedAuthentications, setSelectedAuthentications, FilterName.AUTHENTICATION),
    [FilterName.INITIATEDBY]: createFilterOptions(selectedUsers, setSelectedUsers, FilterName.INITIATEDBY, option => getUserAvatar(option.extraInfo)),
    [FilterName.ACCESSIBILITY]: createFilterOptions(selectedAccessibility, setSelectedAccessibility, FilterName.ACCESSIBILITY),
    [FilterName.STARTDATE]: createFilterOptions(startDate, setStartDate, FilterName.STARTDATE),
    [FilterName.ENDDATE]: createFilterOptions(endDate, setEndDate, FilterName.ENDDATE),
    [FilterName.STATUS]: createFilterOptions(selectedStatuses, setSelectedStatuses, FilterName.STATUS)
  }), [appliedFilters]);

  const [isStatusesChecked, setIsStatusesChecked] = React.useState(false);
  React.useEffect(() => {
    if (selectedStatuses?.length && !isStatusesChecked && filterDict) {
      setIsStatusesChecked(true);
      const optionsToDelete: UIOptionItemNew<ScanStatus[] | string>[] = [];
      const newOptions: UIOptionItemNew<ScanStatus[] | string>[] = [];
      selectedStatuses.forEach(access => {
        if (SCAN_STATUSES.some(option => option.name === access.label && option.id.toString().replaceAll(',', '+') === access.value.toString())) {
          newOptions.push(access);
        }
        else {
          optionsToDelete.push(access);
        }
      });

      if (optionsToDelete.length) {
        filterDict[FilterName.STATUS]?.setSelected?.(newOptions, optionsToDelete, 'removeOption');
      }
    }
  }, [selectedStatuses, filterDict]);

  // TODO: optimize
  // -----Search Start-----
  const [enableRewriteSearchParams, setEnableRewriteSearchParams] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    if (appliedFilters.length || enableRewriteSearchParams) {
      setEnableRewriteSearchParams(false);
      setSearchParams(() => {
        const newUrlSearchParams = new URLSearchParams();
        const groupedFilters = Object.groupBy(appliedFilters, ({ filterName }) => filterName);
        Object.keys(groupedFilters).forEach(filterName => {
          const newValue = groupedFilters[filterName as FilterName]?.map(filter =>
            `${filter.value}/${filter.id.substring(filter.filterName.length + 1)}`).join(',') || '';

          newUrlSearchParams.set(filterName.toLocaleLowerCase(), newValue);
        });
        return newUrlSearchParams;
      }, {
        replace: true
      });
    }
  }, [enableRewriteSearchParams]);

  React.useEffect(() => {
    !enableRewriteSearchParams && setEnableRewriteSearchParams(true);
  }, [JSON.stringify(appliedFilters)]);

  React.useEffect(() => {
    const keys = Array.from(searchParams.keys());
    if (!keys.length) return;
    keys.forEach(key => {
      const filterName = key.split(' ').map(word => word[0].toUpperCase() + word.substring(1)).join(' ') as FilterName;
      const options = searchParams.get(key);
      const newValuesString = options?.split(',');
      const newValues = newValuesString?.map(filter => {
        const label = filter.split('/')[0];
        const value = filter.split('/')[1];
        return ({
          value: value, label: label, key: value,
        });
      });
      if (newValues) {
        filterDict[filterName]?.setSelected(newValues, newValues, 'selectOption');
      }
    });
  }, [searchParams]);

  // -----Search End-----

  return (
    <ScanFiltersContext.Provider
      value={{
        filterDictionary: filterDict,
        appliedFilters: appliedFilters,
      }}
    >
      <ScansPage
        scanSelectionApi={scanSelectionApi}
        itemList={itemList}
        footerItems={footerItems}
        isOpenPlanner={isOpenPlanner}
        setOpenPlanner={setOpenPlanner}
        selectedPlannerScans={selectedPlannerScans}
        setSelectedPlannerScans={setSelectedPlannerScans}
      />
    </ScanFiltersContext.Provider>
  );
};