import { globalConstants, globalEnums, globalUtils } from '@shared/duck';
import { NameCellRender, UrlTextInput } from './components';
import React from 'react';
import { FormikControl, FormikControlMui, UIFileInput, DocsLink, TargetExclusions } from '@shared/components';
import SwaggerFileInput from './components/swagger-url/swagger-url';
import {
  FormControlLabel, RadioGroup, Stack, Typography, Radio, Alert,
} from '@mui/material';
import { useLaunchDarklyFlags } from '@shared/hooks';
import { OpenApiSpecUrl, OpenApiTarget } from '@api-client';
import { useFormikContext } from 'formik';
import { ApiTargetsContentValues } from './duck';

interface ApiTargetsContentProps {
  openApiTarget?: OpenApiTarget;
  project?: string;
  specUrl?: OpenApiSpecUrl;
  setFileEdited: (nextState: boolean) => void;
}

const ApiTargetsContent: React.FC<ApiTargetsContentProps> = ({
  openApiTarget,
  project,
  specUrl,
  setFileEdited,
}: ApiTargetsContentProps) => {
  const { strictSwaggerSpecValidation } = useLaunchDarklyFlags();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<ApiTargetsContentValues>();

  const supportedTypes = [
    globalEnums.EFileExtension.YAML,
    globalEnums.EFileExtension.JSON,
  ];

  const fileValidation = async (file: File): Promise<{ success: boolean; error?: string; }> => {
    const res = await globalUtils.validateOpenApiSpec({ api: file, isFile: true, strictCheck: strictSwaggerSpecValidation });
    return {
      success: !!res.isValid,
      error: res.isValid ? '' : globalConstants.INVALID_YAML_DEFINITION
    };
  };

  return (
    <>
      <Stack flexDirection='row' gap='1.5rem' mt='2rem'>
        <FormikControl name='newTarget.name' label='Target Name' style={{ width: '100%' }} showError>
          <NameCellRender placeholder='example-api' name='newTarget.name' fieldName={'newTarget'} project={project} isInEdit={!!openApiTarget} />
        </FormikControl>
        <FormikControl name='newTarget.location' label='Base URL' style={{ width: '100%' }}>
          <UrlTextInput placeholder='api.nightvision.net' />
        </FormikControl>
      </Stack>

      <Stack gap='1rem'>
        <Typography
          variant='subtitle1'
          lineHeight='2rem' fontSize='1.25rem'
          fontWeight={600}
        >
          Add API Specs
        </Typography>
        <RadioGroup
          row
          value={values.isFileUrl ? 'url' : 'open_api'}
          onChange={e => {
            setFieldValue('isFileUrl', e.target.value === 'url');
          }}
          sx={{
            '& .MuiTypography-root': {
              lineHeight: '1.25rem',
              color: '#111B2B',
              fontSize: '0.875rem',
              fontWeight: 500,
            }
          }
          }
        >
          <FormControlLabel value='url' control={<Radio size='small' />} label='OpenAPI URL' />
          <FormControlLabel value='open_api' control={<Radio size='small' />} label='Upload File' />
        </RadioGroup>

        {values.isFileUrl ? (
          <FormikControlMui name='newTarget.fileLocation' label='OpenAPI URL' mb='1.5rem'>
            <SwaggerFileInput placeholder='Enter OpenAPI URL here...' strictSwaggerSpecValidation={strictSwaggerSpecValidation} />
          </FormikControlMui>
        ) : (
          <FormikControlMui label='Upload a Swagger file' name='newTarget.file' mb='1.5rem'>
            <UIFileInput
              initFile={values.newTarget.file}
              onAfterChange={async file => {
                setFieldTouched('newTarget.file', true);
                setFieldValue('newTarget.file', file);
                setFileEdited(true);
              }}
              accept={supportedTypes}
              fileValidation={fileValidation}
              helpText='.YML, .YAML, or .JSON'
              downloadUrl={specUrl?.url}
            />
          </FormikControlMui>
        )}
      </Stack>

      <Stack gap='1.5rem'>
        <Alert severity='info' >
          {'You can create a target with '}
          <Typography variant='subtitle2' display='inline-block'>
            {'higher end-point coverage'}
          </Typography>
          {' using our API Discovery first, before scanning.'}
          <br />
          <DocsLink
            to={globalConstants.DOCS_PATHS.API_EXTRACTION_OVERVIEW}
            style={{ marginTop: '0.5rem', display: 'block', width: 'fit-content' }}
          >
            {'API Discovery tutorial'}
          </DocsLink>
        </Alert>

        <TargetExclusions />
      </Stack>
    </>
  );
};

export default ApiTargetsContent;
