import { useMemo } from 'react';
import { globalQueries, globalModels, globalUtils, allRoutesEnum } from '@shared/duck';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';

interface TargetsTableApiResult {
  targets: globalModels.TargetViewModel[],
  totalItemsCount: number,
  isTargetsListLoading: boolean,
  targetsHasNextPage: boolean,
  fetchNextTargetsPage: () => void,
  onTargetRowClick?: (item: globalModels.TargetViewModel) => void,
  onDeleteTargetClick: (item: globalModels.TargetViewModel) => void;
}

export const useTargetsTableApi = ({
  page,
  project,
  search,
  pageSize,
}: {
  page: number;
  project?: string;
  search?: string;
  pageSize?: number;
}): TargetsTableApiResult => {
  const navigate = useNavigate();

  const {
    targets = [],
    totalItemsCount,
    isTargetsListLoading,
    targetsHasNextPage = false,
    fetchNextTargetsPage,
  } = globalQueries.useGetTargetsList({
    page: page + 1,
    filter: search,
    project: project ? [project] : undefined,
    pageSize: pageSize,
  });

  const targetsModels = useMemo(() => targets.map(item => globalUtils.mapTargetsDtoToViewModel(item)), [targets]);
  const { deleteTarget } = globalQueries.useDeleteTarget();
  const { showModal } = useGlobalModalContext();

  const onDeleteTargetSubmit = async (id: string, name?: string) => {
    try {
      await deleteTarget({ id });
      enqueueSnackbar(<><b>{name}</b> Target has been deleted successfully</>, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to delete ${name} Target`, { variant: 'error' });
    }
  };

  const onDeleteTargetClick = async (item: globalModels.TargetViewModel) => {
    showModal({
      modalType: MODAL_TYPE.DeleteModal,
      modalProps: {
        title: 'Delete Target?',
        onConfirm: () => onDeleteTargetSubmit(item.id, item.name),
        name: item.name,
      },
    });
  };

  const onTargetRowClick = (item: globalModels.TargetViewModel) => (
    navigate(allRoutesEnum.ProjectTargetDetails.replace(':projectId', item.projectId).replace(':targetId', item.id))
  );

  return {
    targets: targetsModels,
    totalItemsCount,
    isTargetsListLoading,
    targetsHasNextPage,
    fetchNextTargetsPage,
    onDeleteTargetClick,
    onTargetRowClick,
  };
};

export default useTargetsTableApi;
