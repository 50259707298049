import React from 'react';
import { observer } from 'mobx-react';
import { ProjectFilterWrapper } from '@shared/components';
import { ContentGrid } from './home-page.styled';
import { StatisticsProp } from '@shared/duck/queries';
import { IssuesTime, RecentScans } from './components';
import { Grid } from '@contentful/f36-components';
import { globalTypes } from '@shared/duck';
import { Typography } from '@mui/material';

const statsProp: StatisticsProp = 'unique_issues_statistics';

const HomePage: React.FC = () => {
  const [selectedProjects, setSelectedProjects] = React.useState<globalTypes.UIOptionItem<string>[]>([]);

  return (
    <ContentGrid columns={6} rowGap='spacingS' columnGap='spacingS'>
      <Grid.Item className='header-section'>
        <Typography
          variant='h5'
          lineHeight='2.25rem'
        >
          Overview
        </Typography>
      </Grid.Item>
      <Grid.Item className='controls-section'>
        <ProjectFilterWrapper
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
        />
      </Grid.Item>
      <Grid.Item className='time-chart-section'>
        <IssuesTime selectedProjects={selectedProjects} statsProp={statsProp} />
      </Grid.Item>
      <Grid.Item className='recent-scans-section' style={{ marginBottom: '2rem' }}>
        <RecentScans selectedProjects={selectedProjects} />
      </Grid.Item>
    </ContentGrid>
  );
};

export default observer(HomePage);
