import { Alert, Grid, Link } from '@mui/material';
import { DocsLink, FormikControlMui, TargetExclusions } from '@shared/components';
import { useMedia } from '@shared/hooks';
import { useFormikContext } from 'formik';
import {
  AuthSelect,
  BaseUrl,
  BypassUrlValidationCheckbox,
  CreateTargetCheckbox,
  FileSelect,
  OpenApiTargetSelect,
  ProjectSelect,
  TargetSelect,
} from './components';
import { ContainerStyled } from './form-data.styled';
import React from 'react';
import { globalConstants } from '@shared/duck';
import { formDataTypes } from './duck';

interface FormDataProps {
  cliFlow: boolean;
  openApi?: boolean;
  openCliFlow: () => void;
  isAccessible?: boolean;
  readOnly?: boolean;
  setUrlCheckLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormData: React.FC<FormDataProps> = ({
  cliFlow,
  openApi = false,
  openCliFlow,
  isAccessible,
  readOnly = false,
  setUrlCheckLoading,
}) => {
  const { isSmallScreen } = useMedia();
  const { values, errors, touched, setFieldValue } = useFormikContext<formDataTypes.FormDataFormValues>();

  React.useEffect(() => {
    if (cliFlow) {
      setFieldValue('bypassUrlValidationCheckbox', false);
    }
  }, [cliFlow]);

  const additionalBaseUrlError = React.useMemo(() => {
    const isNotAccessible = isAccessible !== undefined && !isAccessible;
    const isValid = !errors.baseUrl?.toString().includes('Invalid format');

    return (
      !cliFlow && isNotAccessible && !!values.baseUrl && isValid ? (
        <>
          {'. Try '}
          <Link
            onClick={openCliFlow}
            underline='hover'
            sx={{
              color: `${globalConstants.INPUT_BORDER.ERROR} !important`,
              fontWeight: '600',
            }}
          >
            CLI helper
          </Link>
          {' instead or:'}
        </>
      ) : <></>
    );
  }, [cliFlow, isAccessible, errors]);

  const targetRequiredError = touched.target ? !values.target && !values.newTargetName : false;

  return (
    <ContainerStyled>
      <Grid
        container
        spacing={1.5}
      >
        <Grid item md={6} xs={12}>
          <FormikControlMui name='project' label='Project' required>
            <ProjectSelect />
          </FormikControlMui>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormikControlMui name='authentication' label='Authentication' optional>
            <AuthSelect />
          </FormikControlMui>
        </Grid>

        <Grid item md={6} xs={12} style={{ paddingBottom: isSmallScreen ? '0' : '0.5rem' }}>
          <FormikControlMui name='target' label='Target Name' required requiredError={targetRequiredError}>
            {openApi ? (
              <OpenApiTargetSelect readOnly={readOnly} />
            ) : (
              <TargetSelect readOnly={readOnly} />
            )}
          </FormikControlMui>
          {!readOnly && (
            <FormikControlMui name='createNewTargetCheckbox' showError style={{ marginTop: '0.25rem' }}>
              <CreateTargetCheckbox />
            </FormikControlMui>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <FormikControlMui
            name='baseUrl'
            label='Base URL'
            required
            additionalError={additionalBaseUrlError}
          >
            <BaseUrl
              cliFlow={cliFlow}
              readOnly={readOnly}
              setUrlCheckLoading={setUrlCheckLoading}
            />
          </FormikControlMui>

          {!cliFlow &&
            <FormikControlMui name='bypassUrlValidationCheckbox' style={{ marginTop: '0.25rem' }}>
              <BypassUrlValidationCheckbox />
            </FormikControlMui>
          }
        </Grid>

        {openApi && (
          <FileSelect cliFlow={cliFlow} />
        )}

        <Grid item xs={12}>
          <TargetExclusions />
        </Grid>

      </Grid>


      {openApi && cliFlow && (
        <Alert severity='info' style={{ marginTop: '1rem' }}>
          {'API specs can be automatically generated with NightVision\'s '}
          <DocsLink
            to={globalConstants.DOCS_PATHS.API_EXTRACTION_OVERVIEW}
          >
            {'API excavator'}
          </DocsLink>
          {'. Using the specs generated by NightVision also enables access to the '}
          <DocsLink
            to={globalConstants.DOCS_PATHS.CODE_TRACEBACK}
          >
            {'Code Traceback'}
          </DocsLink>
          {' feature.'}
        </Alert>
      )
      }
    </ContainerStyled>
  );
};

export default FormData;