import { Theme, InputBaseClasses, OutlinedInputClasses, FormControlClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { globalConstants } from '@shared/duck';

export const InputBaseStyles: Partial<OverridesStyleRules<keyof InputBaseClasses, 'MuiInputBase', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    padding: '0.75rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: '#414D63', // grey700
    backgroundColor: 'white',
    borderRadius: '0.375rem',

    '&.Mui-disabled': {
      backgroundColor: '#F7F9FA', // grey100

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#CFD9E0 !important', // grey300
      }
    },
  },
  input: {
    padding: '0px !important',
  },
  multiline: {
    height: 'unset',
    padding: '0.75rem !important',
  },
  sizeSmall: {
    height: '2.5rem',
  },
};

export const InputOutlinedStyles: Partial<OverridesStyleRules<keyof OutlinedInputClasses, 'MuiOutlinedInput', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    '&.Mui-focused': {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: globalConstants.INPUT_BORDER.FOCUSED,
      },
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: globalConstants.INPUT_BORDER.ERROR,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: globalConstants.INPUT_BORDER.ERROR,
      },
    },
    ':hover .MuiOutlinedInput-notchedOutline': {
      borderColor: globalConstants.INPUT_BORDER.BASE,
    },
    width: '100%',
    borderRadius: '0.375rem',
  },
  notchedOutline: {
    border: `1px solid ${globalConstants.INPUT_BORDER.BASE}`,
    borderRadius: '0.375rem',
  },
};

export const MuiFormControlStyles: Partial<OverridesStyleRules<keyof FormControlClasses, 'MuiFormControl', Omit<Theme, 'components'>>> | undefined =
{
  root: {
    width: '100%',
  }
};
