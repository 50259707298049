/* tslint:disable */
/* eslint-disable */
/**
 * NightVision REST API
 * This is the NightVision REST API, which powers [NightVision Security](https://app.nightvision.net).  ## Available Endpoints  * [Authentication](#auth): exchange user credentials for API access token.  ### Enums  * [Enums](#enums): get all enums.  ### Projects  * [Projects](#projects): manage projects.  ### Credentials  * [Credentials](#credentials): manage credentials used during scans. This includes Headers and Playwright Scripts.  ### Nuclei Templates  * [Nuclei Repositories](#nuclei-repositories): manage Nuclei Repositories used in targets.  ### Targets  * [URL Targets](#targets/targets_url_list): manage URL Targets scanned by NightVision. * [API Specification Targets](#targets/targets_openapi_list): manage API Specification Targets scanned by NightVision. * [GitHub Targets](#targets/targets_github_list): manage GitHub Repository Targets scanned by NightVision. *  * [Targets](#targets): manage Targets by types scanned by NightVision.  Target Type values: - 1 - GitHub - 2 - URL - 3 - OpenAPI  GitHub Target Visibility values: - 1 - Public - 2 - Private - 3 - Internal  ### Scanning  * [Scans](#scans): manage scans.  Scan Status values: - 1 - Succeeded - 2 - Running - 3 - Aborted - 4 - Failed - 5 - Timed Out  Issue Resolution values: - 0 - Open - 1 - Resolved - 2 - False Positive  Check Status values: - 0 - Fail - 1 - Pass - 2 - Disrupted - 3 - In Progress  ### Issues  * [Issues](#issues): manage issues. * [Kinds of Issues](#issue-kinds): manage issue kinds. * [Writeups of Issues](#issue-writeups): manage issue writeups.  ### Checked path info  * [Checked path info](#checked-path-info): Info about all checked paths. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

export const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
        this.name = "RequiredError"
    }
}
